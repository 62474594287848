<template>
  <div class="page-container">
    <van-notice-bar scrollable v-if="donateNotice" :text="donateNotice" />
    <div v-if="donateList.length > 0" class="my-donate-list">
      <div class="my-donate-item" v-for="(item, idx) in donateList" :key="idx">
        <div class="my-donate-title">
          <div class="my-donate-left">
            <p><svg-icon icon-class="no" /> {{ item.number }}</p>
          </div>
          <div class="my-donate-right">
            <p><svg-icon icon-class="date" /> {{ item.time }}</p>
          </div>
        </div>
        <div class="my-donate-info">
          <div class="my-donate-project">
            <p>支持项目: {{ item.project_title }}</p>
          </div>
          <div class="my-donate-amount">
            <p>
              捐赠金额: <span class="red-text">￥{{ item.amount }}</span>
            </p>
          </div>
        </div>
        <div class="my-donate-action">
          <van-row>
            <van-col :span="6">
              <van-button
                plain
                color="red"
                class="my-donate-button"
                round
                type="info"
                :to="{ path: '/donate/' + item.number }"
                >查看详情</van-button
              >
            </van-col>
            <van-col :span="6">
              <van-button
                plain
                color="red"
                class="my-donate-button"
                round
                type="info"
                :to="{ path: '/project/' + item.project_id }"
                >查看项目</van-button
              >
            </van-col>
            <van-col :span="6">
              <van-button
                plain
                color="red"
                class="my-donate-button"
                round
                type="info"
                @click="openCertOverlay(item.number)"
                >{{ item.cert ? "查看证书" : "领取证书" }}</van-button
              >
            </van-col>
            <van-col :span="6" v-if="false">
              <van-button
                disabled
                plain
                color="red"
                class="my-donate-button"
                round
                type="info"
                >{{ item.credit ? "查看票据" : "获取票据" }}</van-button
              >
            </van-col>
          </van-row>
        </div>
      </div>
      <van-divider>已加载全部</van-divider>
    </div>
    <div v-else class="my-donate-list">
      <van-empty description="暂无数据" />
    </div>
    <van-overlay :show="showCert" @click="showCert = false">
      <div class="cert-box">
        <img v-if="certImg" :src="certImg" alt="" />
        <p class="cert-tip">长按证书保存到手机，和朋友分享。</p>
        <p class="cert-tip">点击空白处关闭</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getCertImageByDonateNo } from "@/api/donate";
import { getMyDonateList } from "@/api/mine";
export default {
  data() {
    return {
      donateList: [],
      donateStats: null,
      showCert: false,
      certImg: null,
      donateNotice: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getMyDonateList().then((response) => {
        this.donateList = response.list;
        this.donateNotice = `共捐赠${response.stats.count}次，参加项目${response.stats.project}个,捐赠总额${response.stats.amount}元。感谢您为山东理工大学教育事业的发展做出的贡献。`;
      });
    },
    openCertOverlay(donateNo) {
      this.showCert = true;
      this.certImg = null;
      getCertImageByDonateNo(donateNo).then((response) => {
        this.certImg = response.image;
      });
    },
  },
};
</script>

<style>
.my-donate-stats {
  background-color: #fff;
  padding: 0 1.5rem;
  height: 1.5rem;
}

.my-donate-stats p {
  margin: 0;
  line-height: 1.5rem;
}

.my-donate-list {
}

.my-donate-item {
  min-height: 2rem;
  background-color: #ffffff;
  margin: 0.1rem 0;
  padding: 0.5rem 1.5rem;
}

.my-donate-title {
  height: 1.1rem;
  font-size: 0.8rem;
}

.my-donate-title p {
  margin: 0;
  line-height: 1.1rem;
}

.my-donate-left {
  float: left;
}

.my-donate-right {
  float: right;
}

.my-donate-info {
  display: flex;
}

.my-donate-project {
  width: 60%;
}

.my-donate-amount {
  flex: 1;
}

.my-donate-action {
  height: 2rem;
}
.my-donate-action .van-col {
  text-align: center;
}

.my-donate-button {
  height: 1.2rem !important;
  font-size: 0.7rem !important;
}

.cert-tip {
  text-align: center;
  font-size: 0.7rem;
  color: #ffffff;
}

.cert-box {
  width: 18rem;
  height: 32rem;
  margin: 0 auto;
  margin-top: 7rem;
}

.cert-box img {
  width: 18rem;
}
</style>
