import request from '@/utils/request'

export function getDonateInfo(donate_no) {
  return request({
    url: '/donate/info',
    method: 'get',
    params: { donate: donate_no }
  })
}

export function getDonateRecord(project, group = null, sort = true) {
  return request({
    url: '/donate/record',
    method: 'get',
    params: { project: project, group: group, sort: sort }
  })
}

export function getDonateRecordPro(project, type, by, group = null, sort = true) {
  return request({
    url: '/donate/recordpro',
    method: 'get',
    params: { project: project, type: type, by: by, group: group, sort: sort }
  })
}

export function getCollegeList() {
  return request({
    url: '/college/list',
    method: 'get'
  })
}

export function getCollegeRank(project_id, sort_key = 'amount') {
  return request({
    url: '/college/rank',
    method: 'get',
    params: { project: project_id, sort: sort_key }
  })
}

export function createDonateOrder(data) {
  return request({
    url: '/order/create',
    method: 'post',
    data
  })
}

export function getOrderInfo(order) {
  return request({
    url: '/order/info',
    method: 'get',
    params: { order: order }
  })
}

export function getOrderStatus(order) {
  return request({
    url: '/order/status',
    method: 'get',
    params: { order: order }
  })
}

export function getCertImageByDonateNo(donate_no) {
  return request({
    url: '/getcert',
    method: 'get',
    params: { donate_no: donate_no }
  })
}

export function getDonateRank(project_id) {
  return request({
    url: '/donate/rank',
    method: 'get',
    params: { project: project_id }
  })
}

export function getMessageTemplate(project) {
  return request({
    url: '/donate/message',
    method: 'get',
    params: { project: project }
  })
}
