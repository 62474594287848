import request from '@/utils/request'

export function getMyDonateList (sort = null) {
  return request({
    url: '/mine/donate',
    method: 'get',
    params: { sort: sort }
  })
}

export function getMyCertList () {
  return request({
    url: '/mine/cert',
    method: 'get'
  })
}

export function getMyProfileData () {
  return request({
    url: '/mine/profile/data',
    method: 'get'
  })
}

export function updateMyProfileData (data) {
  return request({
    url: '/mine/profile/update',
    method: 'put',
    data
  })
}

export function deleteMyProfileData () {
  return request({
    url: '/mine/profile/delete',
    method: 'delete'
  })
}

export function getMyAddressData () {
  return request({
    url: '/mine/address/data',
    method: 'get'
  })
}

export function updateMyAddressData (data) {
  return request({
    url: '/mine/address/update',
    method: 'put',
    data
  })
}

export function getMyGroupList () {
  return request({
    url: '/mine/group/list',
    method: 'get'
  })
}